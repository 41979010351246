import next from './img/next.png'
import React, { useState } from 'react';

function Facts() {
    const [factIndex, setFactIndex] = useState(0)
   const facts = [
    'Когда блоков настолько много, что они заполняют весь экран, используйте функцию блокирования схемы (кнопка "🔒" слева внизу).',
    'Для создания блока в определённом месте зажмите зону цвета светло-зелёного моря на любом из доступных блоков, проведите в нужное место и отпустите.',
    'После публикации историю нельзя редактировать.',
    'В случае удаления блока удалятся и все зависимые от него блоки. Блоки добавленные алгоритмом в начале создания истории удалить нельзя, они ставят рамки минимального размера истории.',
    'Блоки нельзя соединять между собой (эта функция в процессе разработки).',
    'Если после добавления адреса картинки фон блока на общей схеме не поменялся, то адрес был не корректен и текст в поле адреса картинки был удалён.',
    'В процессе создания может находиться только одна история.',
    'Становитесь участинком чата @storintell для обсуждения ошибок, обновлений проекта.',
    'Блок может вместить в себя максимум 4000 символов, это связано с ограничениями telegram.',
    'Для удобного добавления больших текстов в блок потяните правый нижний уголок формы вниз (работает и с описанием истории).',
    'Опубликованную историю можно найти чат-боте @storinter по названию, номеру или в топе популярных.',
    'Не забудьте сохраниться перед выходом из приложения!'   
]
    facts.sort(() => Math.random() - 0.5);
return(
    <div className='w-100 mx-3'>
    <p id='label'>{facts[factIndex]}</p>
    <div className='w-100 flex justify-end'>
      <button className="focus:outline-none" onClick={e => {
        if (facts.indexOf(facts[factIndex]) === (facts.length - 1)){
            setFactIndex(0)
        }else {
           setFactIndex(factIndex+1) 
        }
        
      }}><img src={next} alt="img" className='w-6'/></button>
    </div>
    </div> 
)
}

export default Facts;